import * as React from "react";
import { Visibility, Transition, VisibilityEventData } from "semantic-ui-react";
import { Link } from "react-scroll";
import DashedLineGroup, { DashedLine } from "../../controls/dashedLineGroup";
import { ContactUsKey } from "../homePageContainer";
import { ContactTopic } from "./contactSection";
import TechRealEstate from "css/images/homePage/HW-RealEstate-Tech100.png";

// use with semantic ui's Visibility component
export function itemVisible(data: VisibilityEventData): boolean {
  return data.calculations.topVisible || data.calculations.bottomVisible ? true : false;
}

interface State {
  elementVisible: boolean;
}

interface Props {
  setTopic: (name: ContactUsKey, value: ContactTopic) => void;
}

class MissionSection extends React.Component<Props, State> {
  state = {
    elementVisible: false,
  };

  handleVisibilityUpdate = (e: any, data: VisibilityEventData) => {
    if (!this.state.elementVisible && itemVisible(data)) {
      this.setState({ elementVisible: true });
    }
  };

  subheaderTxt = (mobile?: "mobile") => {
    return (
      <div className={`subheader-txt ${mobile || ""}`}>
        <div className="header-2 txt-regular">Get to the closing faster</div>
        <div className="subheader-1 txt-semiBold">
          Find and analyze nationwide property, ownership and mortgage data using any device.
        </div>
        <div className="p-1 txt-extraLight">
          Use Flueid Pro to uncover information at the start of the transaction lifecycle for better client engagement
          and a faster, easier close.{" "}
        </div>
      </div>
    );
  };

  render() {
    const { setTopic } = this.props;
    const { elementVisible } = this.state;
    return (
      <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="homepage-visibility-closing-container">
        <div className="homepage-closing-container">
          <div className="closing-content-container">
            <div className="top-row">
              <div className="header-1">Built to accelerate closings</div>
              <div className="subheader-1 txt-light">
                Flueid Pro has automated overly complex, archaic closing practices for better decision-making and
                efficiency at every milestone in the real estate lifecycle.
              </div>
              {this.subheaderTxt("mobile")}
            </div>
            <div className="closing-content-flex">
              <div className="closing-text-background-container">
                <div className="closing-text-content">
                  <div className="closing-text-paragraphs">
                    {this.subheaderTxt()}
                    <div className="closing-itemsContainer feature-blocks-grid">
                      <div className="closing-item feature-block-grid-item">
                        <div className="closing-Img"></div>
                        <div className="p-1 txt-semiBold">Nationwide Data</div>
                        <div className="p-3 txt-gray">Real property, listing and other data sources</div>
                      </div>
                      <div className="closing-item feature-block-grid-item second">
                        <div className="closing-Img plat"></div>
                        <div className="p-1 txt-semiBold">Multi-Platform</div>
                        <div className="p-3 txt-gray">iOS and Responsive Web</div>
                      </div>
                      <div className="closing-item feature-block-grid-item">
                        <div className="closing-Img valu"></div>
                        <div className="p-1 txt-semiBold">Valuation and Equity</div>
                        <div className="p-3 txt-gray">Accurate property valuation and estimated equity</div>
                      </div>
                      <div className="closing-item feature-block-grid-item forth">
                        <div className="closing-Img hot"></div>
                        <div className="p-1 txt-semiBold">Hot Property Indication</div>
                        <div className="p-3 txt-gray">Properties with a high propensity to turnover are identified</div>
                      </div>
                    </div>
                    <div className="subheader-1 txt-extraLight">
                      <span className="header-3">Interested?</span> We’d love to show you more.
                    </div>

                    <div className="flex items-end flex-wrap my-md">
                      <Link
                        to="flueidProContactForm-text-content"
                        onClick={() => setTopic("topic", ContactTopic.Demo)}
                        smooth={true}>
                        <button className="btn btn-medium btn-black mb-sm mr-sm">Schedule a Demo</button>
                      </Link>
                      <a
                        href="https://apps.apple.com/us/app/flueid-pro/id1463006287"
                        target="_blank"
                        className="icon-appstore mb-sm mr-sm"
                        rel="nofollow noreferrer noopener"
                      />
                    </div>

                    <div>
                      <div className="p-3 mb-xsm">Awarded</div>
                      <a
                        href="https://www.housingwire.com/company-profile/flueid-software-corporation-2/"
                        rel="nofollow noreferrer noopener"
                        target="_blank">
                        <img src={TechRealEstate} width={170} height={40} alt="housing wire award" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <Transition visible={elementVisible} animation="fade up" duration={2500}>
                <div className="closing-transition-container">
                  <div className="closing-container-column-right" />
                </div>
              </Transition>
            </div>
          </div>
          <DashedLineGroup
            animationDirection="left"
            visibilityClassName="mission-section-visibility-line-group-1"
            className="mission-section-dashed-line-group-1"
            render={(visible: boolean) => (
              <>
                <DashedLine width={269} duration={0.5} delay={0.7} x={"-10vw"} show={visible} />
                <DashedLine width={150} duration={1.5} delay={0} x={"-8vw"} show={visible} />
                <DashedLine width={15} duration={1.5} delay={1} x={"-4vw"} show={visible} />
                <DashedLine width={25} duration={2} delay={1} x={"-1vw"} show={visible} />
                <DashedLine width={150} duration={1.5} delay={0} x={"-8vw"} show={visible} />
                <DashedLine width={15} duration={1.5} delay={1} x={"-4vw"} show={visible} />
                <DashedLine width={25} duration={2} delay={1} x={"-1vw"} show={visible} />
              </>
            )}
          />
        </div>
      </Visibility>
    );
  }
}

export default MissionSection;

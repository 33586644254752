import { PropertyDetailLite } from "./propertyDetailLite";
import { PropertyDetail } from "./propertyDetail/propertyDetail";

export type PropertyStatus = "UNKNOWN" | "PENDING" | "LISTED" | "SOLD" | "OFF_MARKET";

export function getPropStatus(prop: PropertyDetailLite): PropertyStatus {
  //================================
  // commenting out for now
  // if (!prop.mlsOrg) return "UNKNOWN";
  //================================

  if (prop.pending) return "PENDING";
  if (prop.listed) return "LISTED";
  if (prop.sold) return "SOLD";
  return "OFF_MARKET";
}

export function getPropDetailStatus(prop: PropertyDetail | null): PropertyStatus {
  //================================
  // https://gitlab.com/flueid/flueid-pro/pro/fp-ui/-/issues/207#note_2414238065
  // - JB keep commented out for now
  // if (!prop?.mlsOrg) return "UNKNOWN";
  //================================

  if (prop?.listingInfo?.isPending) return "PENDING";
  if (prop?.listingInfo?.isListed) return "LISTED";
  if (prop?.listingInfo?.isSold) return "SOLD";
  return "OFF_MARKET";
}
